// hlsenteret fonts.less

// font: outfit. variable, so all paths are the same file. no dedicated italic.

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  //src: url(https://fonts.gstatic.com/s/outfit/v11/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format('woff2');
  src: url("../fonts/outfit.woff2") format("woff2");
}
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/outfit.woff2") format("woff2");
}
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/outfit.woff2") format("woff2");
}