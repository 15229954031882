// x-accordions.less


// Accordion-heading. Accordion is initiated when this class is used on a heading.
h2,
h3,
h4 {
  &.accordion {
    position: relative;
    width: fit-content;
    padding-left: 25px; // Space for arrow
    margin-top: 0;
    margin-bottom: 15px;
    cursor: pointer;
    &::before {
      content: "";
      background: url("../images/chevron.svg") no-repeat center;
      background-size: 10px auto;
      position: absolute;
      left: 0;
      width: 20px;
      height: 1.3em; // Match with heading line-height
      transition: all .3s;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
// Accordion containers, using native details/summary
details.accordion-wrapper {
  height: var(--collapsed); // Js-controlled
  &.animatable {
    transition: height .3s ease-in;
  }
  &,
  summary,
  .accordion-content {
    overflow: hidden; // Needed for height calculations and transition
  }
  summary {
    list-style: none;
    width: fit-content;

    // Marker (browser default arrow)
    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
  // Details when open
  &[open] {
    height: var(--expanded); // Js-controlled
    .accordion {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}
// Accordion-end: Manually added class on any element to end accordion
.accordion-end {
  margin-top: 15px;
}
