// hlsenteret local less

// x-styles
@import "../../../../../../../lib/x1/css/x-0.less";

// local
@import "fonts.less";



// vars

:root {
  // colors
  --color-blue: #546f8e;
  --color-blue-light: #96a8bb;
  --color-green: #34b170;
  --color-green-light: #71d3a0;
  --color-yellow: #ffbe5d;
  --color-yellow-light: #ffeacb;
  --color-purple: #ae87c0;
  --color-purple-light: #d3d1eb;
  --color-red: #ff5756;
  --color-red-light: #ffcab4;

  // other
  --radius: 10px;
}



// mixins

// limit number of text-lines for list/feed items
// due to occurences of some very long introductions
.mixin-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



// general

body {
  font-family: Outfit, sans-serif;
}

a {
  text-decoration-thickness: 1px;
  text-underline-offset: .05em;
}

h1 {
  margin-top: 20px; // in case no breadcrumbs (margin will collapse with breadcrumbs)
  @media (@screen-medium) {
    font-size: 6rem;
    margin-top: 40px;
  }
}

strong {
  font-weight: 600; // Escape firefox weirdness where weight seems to scale off parent.
}



// head admin menu

#head-admin-menu-wrapper {
  display: none;
}



// header

#head-wrapper {
  padding: 0;
}

#header {
  border-bottom: 1px solid var(--color-neutral-tint-80);
  padding: 15px 0;
  .logo {
    background-image: url("../images/logo-color.svg");
    position: relative;
    top: -5px; // tweak vertical alignment
    width: 320px;
  }
  @media (@screen-below-medium) {
    column-gap: 20px;
    .logo {
      width: 250px;
    }
  }
}

// english logo

html[lang="en"] {
  #header {
    .logo {
      background-image: url("../images/logo-color-en.svg");
    }
  }
}



// header search

#header-tools {
  .search-form {
    bottom: 40px;
  }
}



// language select

// on small screen, hide language select
// display it inside main menu when this is open

@media (@screen-below-medium) {
  #header-tools {
    grid-template-columns: auto auto;

    #header-language {
      // faux position inside menu
      position: absolute;
      right: 0;
      top: 130px;
      transition: all .5s;
      > a,
      > span {
        padding: 4px 10px;
      }
    }
  }

  // hide lang select unless main menu is open
  body:not(.sidebar-menu-wrapper-visible) {
    #header-tools {
      #header-language {
        display: none;
      }
    }
  }

  // when search open, move lang select down
  body.header-search-visible {
    #header-tools {
      #header-language {
        margin-top: 85px;
      }
    }
  }
}



// main menu

.sidebar-menu-wrapper {
  .sidebar-menu {
    padding-bottom: 20px;
  }
  .sidebar-global-menu {
    border: none;
  }
  a {
    color: var(--color-blue);
  }
  .vrtx-tab-menu {
    a {
      font-weight: 300;
      font-size: 2.4rem;
      @media (@screen-medium) {
        font-size: 3rem;
      }
    }
  }
}



// frontpage specifics

.the-frontpage {
  #header {
    border: none;
  }
  // Allow free height on images in non-column feed to better support portrait format
  // Mainly for news section
  .vrtx-frontpage-box:not([class*=column-feed]) {
    .vrtx-feed img {
      aspect-ratio: unset;
    }
  }
}



// breadcrumbs

#breadcrumbs {
  #vrtx-breadcrumb-wrapper {
    margin-top: 10px;
    @media (@screen-medium) {
      margin-bottom: 50px;
    }
  }
  // include second to last crumb on mobile
  span:nth-last-child(3) {
    @media (@screen-below-medium) {
      display: inline-flex;
    }
  }
}



// footer

#footer-wrapper {
  background-color: var(--color-blue);
  padding-bottom: 100px;
}



// subfolder-menu

#main .vrtx-subfolder-menu {
  background: none;
  padding: 0;
}



// sub-menu and subfolder-menu display settings

#global-sub-menu,
#left-menu-same-level-folders {
  display: none;
}

#vrtx-article-listing,
#vrtx-event-listing {
  // vrtx-subfolder-menu container
  #vrtx-collections {
    display: none;
  }
}



// button-links

.button {
  background-color: var(--color-yellow);
  border-color: transparent;
}

.button-large {
  background-color: var(--color-blue);
}



// tab

.tab,
.mixin-tab {
  background-color: inherit;
  border-radius: var(--radius) var(--radius) 0 0;
  width: fit-content;
  padding: 8px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size-tiny);
  margin-right: 15px; // fail-safe to not go full width
  position: relative;
  // default margins for column feeds. may need tweaking for other use
  top: -53px;
  margin-bottom: -37px;
}



// frontpage row

.grid-container {
  @media (@screen-below-large) {
    margin-bottom: calc(2 * var(--space));
  }
  // top-spacing to h1 if no intro text
  &:first-child {
    @media (@screen-medium) {
      margin-top: 40px;
    }
  }
  // disable colored bg variant
  &.row-all-colored {
    background: none;
    padding: 0;
    @media (@screen-large) {
      margin-bottom: calc(3 * var(--space));
    }
  }
  // general limit to text width
  p {
    max-width: var(--width-soft-max);
  }
}



// media-wide frontpage row

.grid-container.media-wide {
  margin-top: 0;
  margin-bottom: var(--space);
  .vrtx-box-content {
    background-color: var(--color-yellow-light);
    padding-bottom: 40px;
    .tab {
      top: -63px;
      margin-bottom: -37px; // repeated to override general p rule
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  img {
    margin: 0;
  }
}



// Background-video

.grid-container.background-video {
  margin-top: -82px; // Temp move to top of page
  margin-bottom: calc(1 * var(--space));
  @media (@screen-below-medium) {
    margin-top: -52px;
  }
  .text-box {
    background-color: var(--color-yellow-light);
    padding-top: 30px;
    padding-bottom: 40px;
    .tab {
      top: -63px;
      margin-bottom: -37px;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  img {
    margin: 0;
  }
}

// Enable background-video component
.vrtx-background-video {
  &::before {
    display: none;
  }
  video {
    opacity: 1;
  }
}



// -specials

#main {
  .vrtx-frontpage-box {
    // img-special
    &.img-special-right,
    &.img-special-left {
      background-color: var(--color-yellow-light);
      img {
        aspect-ratio: 4/3;
        margin: 0;
      }
      h2 {
        margin-bottom: 20px;
      }
    }
    // box-special
    &.box-special {
      background-color: var(--color-red-light);
      h2 {
        margin-bottom: 20px;
      }
      p {
        @media (@screen-medium) {
          font-weight: 300;
        }
      }
      .read-more {
        &::before {
          background-color: white;
        }
      }
    }
    // link-special
    &.link-special {
      .vrtx-box-content {
        font-weight: 600;
      }
      a::after {
        background-color: var(--color-yellow);
      }
    }
    // intro-special (custom, local)
    &.intro-special {
      color: var(--color-blue);
      @media (@screen-large) {
        // no word-break
        -webkit-hyphens: initial;
        hyphens: initial;
        overflow-wrap: initial;
      }
      p {
        max-width: 950px; // Small, ugly tweak to balance text. Possible temp. Depends on content.
        @media (@screen-medium) {
          font-size: 3rem;
          font-weight: 300;
        }
      }
      .read-more {
        font-size: 1.8rem;
        &::before {
          background-color: var(--color-blue);
          background-image: url("../images/arrow-white.svg");
        }
      }
    }
  }
}



// tiles/navigation-links

#main .navigation-links { // added main to override bg for colored row
  li {
    @media (@screen-large) {
      font-size: 2.4rem;
      font-weight: 300;
    }
    a {
      background-color: var(--color-purple-light);
      &::after {
        width: 30px;
      }
    }
  }
}



// search form (include-component)

.vrtx-search-form {
  fieldset {
    background-color: var(--color-yellow-light);
    padding: 20px;
    max-width: 600px;
    @media (@screen-medium) {
      font-size: 2.4rem;
    }
    input,
    button {
      padding: 7px 15px;
      border-color: var(--color-neutral-tint-30);
      &:not(:hover) {
        background-color: white;
      }
    }
  }
}



// more links

.read-more,
.vrtx-more a {
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: normal;
  line-height: 1.3;
  &::before {
    flex-shrink: 0;
    background-color: var(--color-yellow);
    background-size: 24px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 100%;
    margin-right: 12px;
  }
}



// feeds in columns

[class*="column-feed"] {
  .vrtx-feed li {
    background-color: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  }
  .vrtx-event-component-item {
    background-color: var(--color-yellow-light);
    // event-type (spesielle innstillinger)
    .event-type {
      .mixin-tab;
      span {
        // show only first type in case of multiple
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
  .vrtx-event-component-main,
  .vrtx-list-item-content {
    background-color: inherit; // to further inherit to tab
  }
  .vrtx-list-item-content {
    padding: 0 20px 15px;
    @media (@screen-below-medium) {
      padding: 0 15px 15px;
    }
    // if no image
    &:first-child {
      margin-top: 30px; // not pretty, but prevents collision with row-heading
    }
  }
  .item-description,
  .vrtx-event-component-introduction {
    p {
      // limiting number of lines
      .mixin-line-clamp;
      // if multiple paragraphs, hide all but first
      &:not(:first-child) {
        display: none;
      }
    }
  }
}



// event list page

#vrtx-event-listing {
  // each event
  .vrtx-resource {
    background-color: var(--color-yellow-light);
    gap: 0;
    @media (@screen-medium) {
      margin-top: 40px; // ensure space for tab above first item (margin will collapse)
    }
    img {
      margin: 0;
      // image fills container height if text is taller (occurs mainly at tablet-width)
      // aspect-ratio serves as minimum
      height: 100%;
    }
    .vrtx-list-item-content {
      display: grid;
      align-content: start;
      background-color: inherit; // to further inherit to tab
      padding: 20px 20px 30px;
      @media (@screen-large) {
        padding-left: 40px;
      }
    }
    .event-type {
      .mixin-tab;
      // Move to top
      order: -1;
       // show only first type in case of multiple
      span {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
}



// event and article list page revert from column view

#vrtx-event-listing,
#vrtx-article-listing {
  #vrtx-content {
    display: block;
  }
  #vrtx-additional-content {
    background: none;
    padding: 0;
    margin-left: 0;
    margin-top: 100px;
    max-width: none;
  }
}



// Project pages (with right column)

#vrtx-project-listing,
#vrtx-structured-project {
  h1 {
    margin-top: 0;
    @media (@screen-large) {
      font-size: 5rem; // reduced due to right column
    }
  }
  #vrtx-additional-content {
    background: none;
    padding: 0;
    @media (@screen-large) {
      margin-top: 20px;
    }
    > div {
      background-color: var(--color-yellow-light);
      padding: 30px;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 3rem;
    }
    .vrtx-person-list-participants {
      .all-messages {
        display: none; // Hide link to all participants.
      }
    }
  }
}



// project-list page

#vrtx-project-listing {
  .vrtx-projects {
    margin: 40px 0 60px;
  }
  .vrtx-project {
    display: block;
    margin-bottom: 20px;
    .vrtx-image {
      display: none;
    }
    .vrtx-title {
      font-weight: normal;
      a {
        .mixin-arrow-link;
        font-weight: inherit;
        display: inline-flex;
        align-items: start;
        &::before {
          flex-shrink: 0;
          height: 1.5em;
        }
      }
    }
  }
}



// project list component

.vrtx-comp-project-list {
  ul {
    list-style: none;
    padding: 0;
    a {
      .mixin-arrow-link;
      font-weight: inherit;
      display: inline-flex;
      align-items: start;
      &::before {
        flex-shrink: 0;
        height: 1.5em;
      }
    }
  }
}



// tag view

#vrtx-tagview .vrtx-resource {
  img,
  .introduction {
    margin-bottom: 15px;
  }
  .introduction {
    p {
      // limiting number of lines
      .mixin-line-clamp;
      // if multiple paragraphs, hide all but first
      &:not(:first-child) {
        display: none;
      }
    }
  }
}



// time and place container (used in event feed, list and on event page)

.vrtx-time-and-place-container {
  &::before {
    content: "";
    background: url("../images/calendar.svg") no-repeat center;
    padding: 2px 24px 2px 0;
    margin-right: 6px;
  }
}



// rss feed link. not in use

#vrtx-feed-link,
.vrtx-feed-link {
  display: none;
}



// introduction

.vrtx-introduction {
  font-weight: 300;
  @media (@screen-medium) {
    margin-bottom: 70px;
  }
  // limit text width
  * {
    max-width: var(--width-soft-max);
  }
}



// article elements

.vrtx-imagetext,
figcaption {
  font-size: var(--font-size-small);
  font-weight: normal; // prevent inheriting font-upscale
}

.vrtx-article-body {
  // font upscale
  @media (@screen-medium) {
    font-size: 2.2rem;
    font-weight: 300;
  }
  .vrtx-facts-container {
    background-color: var(--color-purple-light);
    font-size: inherit;
    &.vrtx-container-left,
    &.vrtx-container-right {
      font-size: var(--font-size-small);
      font-weight: normal;
    }
  }
  blockquote {
    margin: 60px 0;
    color: var(--color-blue);
    &::before {
      content: "";
      display: block;
      height: 40px;
      background: url("../images/quote.svg") no-repeat;
      background-size: contain;
      margin-bottom: 25px;
    }
    p:first-child {
      font-style: normal;
      line-height: 1.3;
      @media (@screen-medium) {
        font-size: 3rem;
      }
    }
  }
  .button {
    margin: 50px 0;
  }
}



// article page

#vrtx-structured-article {
  @media (@screen-medium) {
    .vrtx-introduction {
      margin-bottom: 90px;
    }
  }
  // added in tpl at bottom
  .link-special {
    background-color: var(--color-yellow-light);
    border: none;
    max-width: 700px;
    margin-top: 80px;
    @media (@screen-medium) {
      padding: 5% 10% 5% 5%;
      .vrtx-box-content {
        font-size: 5rem;
      }
    }
    // arrow
    a::after {
      background-size: 24px;
      width: 36px;
      height: 36px;
      margin-left: 10px;
    }
  }
}



// event page

#vrtx-structured-event {
  .vrtx-introduction {
    margin-bottom: 40px;
  }

  // byline
  .vrtx-byline {
    @media (@screen-medium) {
      margin-bottom: 80px;
    }
    .vrtx-time-and-place-container {
      position: relative;
      padding-left: 52px; // space for icon
      line-height: 1.3;
      font-weight: bold;
      span {
        font-weight: normal;
      }
      > *:nth-last-child(2) {
        margin-right: 5px; // spacing before "add to calendar"
      }
      // icon
      &::before {
        background-color: var(--color-yellow);
        background-position: center 7px;
        position: absolute;
        left: 0;
        // center vertically
        top: 0;
        bottom: 0;
        margin: auto 0;
        padding: 0;
        border-radius: 100%;
        width: 40px;
        height: 40px;
      }
      .vrtx-number-of-comments-add-event-container {
        display: inline-block;
      }
    }
  }
}



// more-events (feed below main content on event page)

.more-events {
  margin-top: 80px;
  border-top: 1px solid var(--color-neutral-tint-80);
  padding-top: 20px;
  @media (@screen-large) {
    margin-left: -200px; // escape left indent
  }
  // if no duplicate, last item is removed. feed fetches 4 items.
  &:not(.has-duplicate) {
    .last {
      display: none;
    }
  }
  // duplicate of current page removed. js-detected
  .duplicate {
    display: none;
  }
}



// opening-hours and prices

// frontpage row class

.opening-hours-and-prices-row {
  .vrtx-frontpage-box {
    background-color: var(--color-purple-light);
    padding: 5%;
    max-width: none;
    .read-more::before {
      background-color: white;
    }
  }
  .vrtx-box-content {
    display: grid;
    gap: var(--space);
    @media (@screen-large) {
      grid-template-columns: 1fr 1fr;
    }
  }
  table {
    @media (@screen-medium) {
      font-size: 2.4rem;
    }
    th,
    td {
      border-width: 1px 0;
    }
    th {
      padding-left: 0;
    }
  }
}

// tables (used for both hours and prices)

.visit-table {
  @media (@screen-medium) {
    font-size: 2.4rem;
  }
  th,
  td {
    border-width: 1px 0;
  }
  th {
    padding-left: 0;
  }
}

// opening-hours
// copied from museums. to control which languages are displayed.

.opening-hours-include .vrtx-shared-text-description>*:not(table):not(.table-wrapper-responsive) {
  display: none;
}

body:not(.en) .opening-hours-include .vrtx-shared-text-description {
  &:last-child {
    display: none;
  }
}
body.en .opening-hours-include .vrtx-shared-text-description {
  &:first-child {
    display: none;
  }
}



// vrtx-person-listing page

#vrtx-person-listing {
  .vrtx-subfolder-menu {
    .menu-title {
      display: none;
    }
  }
  // search field custom-added to page
  .vrtx-search-container {
    max-width: 500px;
    margin-bottom: 60px;
    form {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      background-color: var(--color-neutral-tint-95);
      padding: 20px;
      // Not sure if this h3 will stay
      h3,
      label {
        grid-column: span 2;
        margin: 0;
      }
      input,
      button {
        border-color: var(--color-neutral-tint-30);
        &:not(:hover) {
          background-color: white;
        }
      }
      input {
        width: 100%; // works like max-width
      }
      button {
        white-space: nowrap;
      }
    }
  }
}



// vrtx-person-listing table

table.vrtx-person-listing {
  tbody {
    tr:nth-child(odd) {
      background-color: var(--color-yellow-light);
    }
  }
}



// vrtx-person page

// Page id
#vrtx-person {
  h1 {
    margin-bottom: 10px;
  }
  .vrtx-date-info {
    display: none;
  }
  .vrtx-article-body {
    font-size: inherit;
    font-weight: inherit;
  }
}

#vrtx-person-position,
.vrtx-person-contactinfo .vrtx-value {
  @media (@screen-large) {
    font-size: 2.4rem;
    line-height: 1.3;
    font-weight: 300;
  }
}

#vrtx-person-contact-info-wrapper {
  position: relative;
  background-color: var(--color-yellow);
  max-width: var(--width-soft-max);
  padding: 40px;
  margin: 40px 0;
  @media (@screen-large) {
    min-height: 500px; // 2/3 image ratio (unless min-height exceeded by text content)
    padding-left: 400px; // Space for image
    &:not(:has(img)) { // Reverse :has condition for img. Will leave space if unsupported.
      padding-left: 40px;
    }
  }
  @media (@screen-below-medium) {
    padding: 20px;
  }
}

.vrtx-person-image {
  @media (@screen-large) {
    position: absolute;
    left: 0;
    top: 0;
    width: 333px; // 2/3 image ratio. controlled by wrapper min-height.
    height: 100%; // fill wrapper height
    object-fit: cover;
  }
}

.vrtx-person-contact-info-line {
  margin-bottom: 20px;
}

// hide vcard/visittkort
#vrtx-person-vcard {
  display: none;
}
