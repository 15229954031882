// x-study-program-finder.less


// Page layout
body#vrtx-fs-studieprogram-finder {
  // Move main page-wrapper dimensions to contained elements
  #main {
    margin: 0;
    padding: 0;
    max-width: none;
  }
  // Add main wrapper dimensions
  #vrtx-breadcrumb-wrapper,
  h1,
  #study-program-finder {
    .mixin-page-wrapper;
  }
  // Main content
  #vrtx-main-content {
    background-color: var(--color-neutral-tint-95);
    padding: 20px 0;
  }
  // Hide global sub menu (at page bottom)
  #global-sub-menu {
    display: none;
  }
}



// Finder
#study-program-finder {
  @media (@screen-large) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  // Filter
  .studies-filter {
    font-size: var(--font-size-small);
    margin-bottom: 20px;

    .inner {
      background-color: white;
      padding: 20px;
      @media (@screen-large) {
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow: auto;
      }
    }
    h2 {
      font-size: var(--font-size-base);
      font-weight: normal;
      color: var(--color-neutral-tint-25);
      margin: 0 0 5px 0;
    }
    input,
    select {
      outline-color: currentColor;
    }
    // Text search filter
    .text-filter {
      background-color: white;
      padding: 20px 20px 25px;
      margin-bottom: 20px;
      form {
        position: relative;
      }
      input {
        width: 100%;
        padding-right: 50px; // Space for button
        font-size: var(--font-size-large-05);
        &::placeholder {
          font-size: var(--font-size-small);
        }
      }
      button {
        background: url("../images/search.svg") no-repeat center;
        background-size: 18px;
        position: absolute;
        right: 0;
        width: 40px;
        height: 100%;
        border: none;
      }
    }
    // Checkbox filter. Used to toggle available studies
    .checkbox-filter {
      margin-bottom: 20px;
      font-size: var(--font-size-base);
      // Hide unless facet in use. Js-detected
      &:not(.facet-in-use) {
        display: none;
      }
      label {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        strong {
          font-weight: normal;
          text-transform: lowercase; // Small hack to keep capital letter in filter output
        }
      }
    }
    // Button filter. Radio buttons, used for study topics
    .button-filter {
      li {
        display: inline-block;
        margin: 0 5px 10px 0;
      }
      label {
        position: relative;
        display: block;
        border: 1px solid;
        border-radius: var(--radius);
        padding: 5px 10px;
        cursor: pointer;
        &:focus-within {
          box-shadow: 0 0 0 2px white, 0 0 0 4px black;
        }
        &:hover {
          background-color: var(--color-neutral-tint-95);
        }
        &.selected {
          background-color: black;
          border-color: transparent;
          color: white;
        }
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
    // Dropdown filter
    .dropdown-filter {
      select {
        width: 100%;
      }
    }
    // Topics filter group
    .topics-filter-group {
      margin-bottom: 20px;
      @media (@screen-below-medium) {
        .button-filter {
          label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80vw;
          }
        }
      }
    }
    // Secondary filter group
    .secondary-filter-group {
      display: grid;
      gap: 20px;
      @media (@screen-medium) {
        grid-template-columns: 1fr 1fr;
      }
      @media (@screen-below-medium) {
        margin-top: 20px;
        &:not(.expanded) {
          display: none;
        }
      }
    }
    // Expand-filters button. Used for small screen to show/hide secondary filters
    .expand-filters {
      display: flex;
      background: none;
      padding: 0;
      border: none;
      font-size: var(--font-size-base);
      &::before {
        content: "";
        background: url("../images/chevron.svg") no-repeat center;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        align-self: center;
        margin-right: 3px;
        transition: all .3s;
      }
      &.expanded {
        &::before {
          transform: rotate(90deg);
        }
      }
      @media (@screen-medium) {
        display: none;
      }
    }
  }
  // Studies feeback (message about hits + selected facets output)
  .studies-feedback {
    background-color: #0001;
    padding: 15px 20px;
    margin-bottom: 20px;
    // Message
    .studies-message {
      h2 {
        font-size: var(--font-size-large-10);
        font-weight: normal;
        margin: 0;
      }
    }
    // Facets output
    .selected-facets-output {
      &:not(:empty) {
        margin-top: 10px;
      }
      span {
        &:not(:last-child)::after {
          content: "•";
          margin-left: 5px;
          opacity: .5;
        }
      }
    }
    // Button to clear facets
    .clear-facets {
      margin: 15px 0 5px;
    }
    &.no-selected-facets {
      .clear-facets {
        display: none;
      }
    }
  }
  // Studies list
  .studies-list {
    li {
      margin-bottom: 20px;
      &.inactive {
        display: none;
      }
      // If no-applicants facet present
      &:has(.no-applicants) {
        .facet:not(.no-applicants) {
          display: none;
        }
        a {
          background: none;
          border: 1px dashed var(--color-neutral-tint-30);
        }
        img {
          display: none;
        }
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      background-color: white;
      text-decoration: none;
      color: inherit;
      transition: all .3s;
      &:hover {
        transform: scale(1.03);
      }
      > div {
        padding: 20px 20px 25px;
        @media (@screen-below-medium) {
          font-size: var(--font-size-small);
        }
      }
    }
    img {
      order: 2;
      margin: 0;
      object-fit: cover;
      @media (@screen-medium) {
        width: 120px;
        align-self: stretch;
      }
      @media (@screen-below-medium) {
        width: 60px;
        height: 60px;
        align-self: center;
      }
    }
    h3 {
      margin-bottom: 5px;
      @media (@screen-below-medium) {
        font-size: var(--font-size-large-05);
      }
    }
    span {
      &:not(:last-child)::after {
        content: "•";
        margin-left: 5px;
        opacity: .5;
      }
    }
    // Hide topic/category
    .topic {
      display: none;
    }
  }
}