// x-header.less


// Hidden nav (skip-links)
#hidnav-wrapper {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    position: absolute;
    left: -100000px;
    z-index: 12; // Above admin-menu and header
    padding: 5px;
    margin: 5px;
    background: white;
    text-decoration: none;
    &:focus {
      left: 0;
    }
  }
}



// Header
#head-wrapper {
  position: relative;
  z-index: 10;
  padding: 20px 0;

  @media (@screen-below-medium) {
    padding: 10px 0;
  }
  img {
    margin: 0;
  }
}
#header {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 30px;
  transition: all .3s;

  // Logo
  .logo {
    background: url("../images/logo.svg") no-repeat left center;
    background-size: contain;
    width: 250px;
    height: 90px; // Determines header height
    margin-right: auto;

    @media (@screen-below-medium) {
      width: 150px; // Will shrink (flex)
      height: 60px;
    }
    // If using content img or plain text instead of backround-image
    display: flex;
    align-items: center;
    img {
      max-height: 100%;
      object-fit: contain;
      object-position: left center;
    }
    // Logo text. Visible to screen-reader only
    span {
      .invisible;
    }
  }
  // UiO logo
  .uio-logo {
    flex-shrink: 0;
    background: url("../images/uio-logo.svg") no-repeat right center;
    background-size: contain;
    width: 200px;
    height: 50px;
    margin-right: 5%;

    @media (@screen-below-large) {
      display: none;
    }
  }
}

// English logos
html[lang=en] #header {
  .logo {
    background-image: url("../images/logo-en.svg");
  }
  .uio-logo {
    background-image: url("../images/uio-logo-en.svg");
  }
}

// Header-tools
// Contains language-selector and -popup, search-button and -form and menu-button
#header-tools {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  column-gap: 30px;

  @media (@screen-below-medium) {
    column-gap: 20px;
  }
  // Language
  #header-language {
    display: flex;
    align-items: center;
    gap: 10px;
    > span,
    > a {
      text-transform: uppercase;
      font-size: 1.4rem; // Intentionally not using var
      white-space: nowrap;
    }
    > span {
      border: 1px solid;
      padding: 0 5px;
    }
    > a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    // Switch order when secondary language selected
    .lang-primary {
      order: -1;
    }
  }
  // Language popup, using js from groups/all
  #header-language-tooltip {
    &:not(.visible) {
      display: none;
    }
    align-self: start;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 40px;
    background-color: var(--color-nearly-black);
    padding: 30px;
    width: 450px;
    max-width: 80vw;
    &,
    * {
      color: white;
    }
    p {
      margin-bottom: 10px;
      &:first-of-type {
        font-weight: bold;
      }
      &:last-child {
        margin: 0;
      }
    }
    #header-language-tooltip-close {
      position: absolute;
      right: 0;
      top: 0;
      background: url("../images/x-small-white.svg") no-repeat center;
      background-size: 14px;
      margin: 5px;
      width: 30px;
      height: 30px;
      &:hover {
        outline: 1px solid;
      }
    }
  }
  // Search- and menu buttons
  .toggle-search,
  .sidebar-menu-toggle {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: "";
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: contain;
    }
    @media (@screen-below-medium) {
      &::before {
        margin: 0;
      }
      span {
        .invisible; // Hide text
      }
    }
  }
  .toggle-search {
    &::before {
      background-image: url("../images/search.svg");
    }
  }
  .sidebar-menu-toggle {
    &::before {
      background-image: url("../images/menu.svg");
      width: 36px;
    }
  }
  // Search form
  .search-form {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
  }
}



// Search-form in header-tools or in menu
#header-tools,
#main-menu {
  .search-form {
    form {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      max-width: 600px; // Follows menu
      margin: 0 auto;
      // Input label
      .search-string-label {
        .invisible;
      }
      input {
        width: 100%; // Does what max-width should do
      }
      button {
        white-space: nowrap;
      }
      @media (@screen-medium) {
        input,
        button {
          padding: 7px 15px;
        }
        input {
          font-size: var(--font-size-large-10);
          &::placeholder {
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }
}



// When search not visible
body:not(.header-search-visible) {
  #header-tools {
    // Search-form. Js-controlled
    .search-form {
      display: none;
    }
  }
}
// When search visible
body.header-search-visible {
  #header {
    padding-bottom: 100px; // Space for search-form
  }
}
